<template>
  <div class="task-result-container">
    <div class="form-container">
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm">
        <el-form-item prop="keyword">
          <div class="search-item">
            <el-button class="result-button" icon="el-icon-link" type="primary" v-throttle="toResult">结果面板</el-button>
            <el-input class="search-input" v-model="ruleForm.keyword" placeholder="请输入类型/日期/地域/源地址，参考以下格式" @keyup.enter.native="search"></el-input>
            <el-button class="search-button" icon="el-icon-search" type="primary" v-throttle="search">搜索</el-button>
          </div>
        </el-form-item>
      </el-form>
    </div>
    <!-- 加载完毕 -->
    <div class="search-tags-container" v-for="(item, index) in keywordsData" :key="index">
      <div class="section-container">
        <div class="tag-title">{{ item.name }}</div>
        <el-tooltip effect="light" :content="subItem.desc" placement="top" v-for="(subItem, index) in item.list" :key="index">
          <span class="tag" @click="getKeyword(subItem.text)">{{ subItem.text }}</span>
        </el-tooltip>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TaskResult',
  data() {
    var validateKeyword = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入你要搜索的关键词'));
      } else {
        callback();
      }
    };
    return {
      role: {
        name: 'guest',
        text: '游客',
        level: 1
      }, // 用户默认角色
      ruleForm: {
        keyword: '',
      },
      rules: {
        keyword: [
          { required: true, validator: validateKeyword, trigger: 'blur' }
        ],
      },
      // 关键词数据
      keywordsData: [
        {
            "name": "类型",
            "list": [
              {
                text: "酒店源",
                desc: "酒店源"
              },
              {
                text: "组播源",
                desc: "组播源"
              },
              {
                text: "地波源",
                desc: "地波源"
              },
            ]
        },
        {
            "name": "日期",
            "list": [
              {
                text: "2024",
                desc: "2024年"
              },
              {
                text: "01.01",
                desc: "1月1日"
              },
              {
                text: "2024.03.18",
                desc: "2024年3月18日"
              },
              {
                text: "2025.02",
                desc: "2025年2月"
              },
            ]
        },
        {
            "name": "地域",
            "list": [
              {
                text: "CN",
                desc: "中国"
              },
              {
                text: "Guangdong",
                desc: "广东"
              },
              {
                text: "Shanghai",
                desc: "上海"
              },
              {
                text: "Beijing",
                desc: "北京"
              },
              {
                text: "Shenzhen",
                desc: "深圳"
              },
              {
                text: "Chongqing",
                desc: "重庆"
              },
              {
                text: "Shaanxi",
                desc: "陕西"
              },
              {
                text: "Inner Mongolia",
                desc: "内蒙古"
              },
            ]
        },
        {
            "name": "源地址",
            "list": [
              {
                text: "_ZHGXTV_Public_json_live_interface。txt",
                desc: "智慧光迅"
              },
              {
                text: "_api_post？item=itv_traffic",
                desc: "秒开"
              },
              {
                text: "_newlive_manager_index。php",
                desc: "华视美达"
              },
              {
                text: "_iptv_live_1000。json",
                desc: "智能桌面"
              },
              {
                text: "_status",
                desc: "组播"
              },
            ]
        },
      ],
    };
  },
  methods: {
    // 在新标签页中打开链接
    toResult() {
      window.open("https://isus.cc/result/", '_blank');
    },
    // 获取关键词
    getKeyword(keyword) {
      this.ruleForm.keyword = keyword;
    },
    // 在新标签页中打开链接
    search() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          window.open(`https://isus.cc/result/auth.php?search=${this.ruleForm.keyword}`, '_blank');
        }
      });
    },
  },
}
</script>

<style scoped lang="scss">
.task-result-container ::v-deep {
  margin-top: 200px;

  /* 移动端样式（小于 768px） */
  @media screen and (max-width: 767px) {
    /* 表单宽度 */
    .form-container {
      width: 300px;
    }
  }

  /* PC端样式（大于等于 768px） */
  @media screen and (min-width: 768px) {

    /* 表单宽度 */
    .form-container {
      width: 650px;
    }
  }

  .form-container {
    margin: 0 auto;
    .search-item {
      display: flex;
      justify-content: center;
      align-items: center;

      .result-button {
        width: 150px;
        padding: 10px;
        line-height: 1.15;
        font-size: 16px;
        cursor: pointer;
        border: 1px solid #409EFF;
        border-radius: 20px 0 0 20px;
      }

      .search-input {
        line-height: 1.15;
        .el-input__inner {
          padding: 0 10px;
          font-size: 16px;
          border: 1px solid #BBB;
          border-radius: 0;
        }
      }

      .search-button {
        width: 120px;
        padding: 10px;
        line-height: 1.15;
        font-size: 16px;
        cursor: pointer;
        border: 1px solid #409EFF;
        border-radius: 0 20px 20px 0;
      }
    }
  }
  .search-tags-container {
    &::after {
      content: "";
      display: table;
      clear: both;
    }
    .section-container {
      float: left;
      margin: 20px;
      padding: 30px 20px;
      border-radius: 5px;
      box-shadow: 0 0 10px 1px rgba(64, 158, 255, 0.5);
      &::after {
        content: "";
        display: table;
        clear: both;
      }
      .tag-title {
        width: 200px;
        margin-top: -40px;
        margin-bottom: 10px;
        padding-left: 10px;
        text-align: left;
        font-size: 20px;
        color: #409EFF;
      }
      .tag {
        float: left;
        padding: 5px 10px;
        margin: 5px 10px 5px 0;
        font-size: 14px;
        color: #409EFF;
        cursor: pointer;
        border-radius: 5px;
        box-shadow: 0 0 10px 1px rgba(64, 158, 255, 0.5);
        &:hover {
          background-color: #B3D4FC;
        }
      }
    }
  }

}
</style>
