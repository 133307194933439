<template>
  <div id="home">
    <!-- 头部 -->
    <div class="header-container">
      <!-- 网站标题 -->
      <h1 class="title">全王探测</h1>
    </div>
    <!-- 内容 -->
    <div class="content-container">
      <!-- 选项卡容器 -->
      <div class="tab-container">
        <el-tabs type="card" v-model="activeName" @tab-click="selectTab">
          <el-tab-pane label="资源搜索" name="first">
            <Search v-if="activeName === 'first'" />
          </el-tab-pane>
          <el-tab-pane label="节目解析" name="second">
            <Parse v-if="activeName === 'second'" />
          </el-tab-pane>
          <el-tab-pane label="当前任务" name="third">
            <CurrentTask v-if="activeName === 'third' && role.level >= 10" ref="currentTaskRef" @change="toTab" />
            <Unauthorized v-if="activeName === 'third' && role.level < 10" />
          </el-tab-pane>
          <el-tab-pane label="任务管理" name="fourth">
            <ManageTask v-if="activeName === 'fourth' && role.level >= 10" ref="manageTaskRef" />
            <Unauthorized v-if="activeName === 'fourth' && role.level < 10" />
          </el-tab-pane>
          <el-tab-pane label="任务结果" name="fifth">
            <TaskResult v-if="activeName === 'fifth' && role.level >= 50" />
            <Unauthorized v-if="activeName === 'fifth' && role.level < 50" />
          </el-tab-pane>
          <el-tab-pane label="精选组播" name="sixth">
            <DDNS v-if="activeName === 'sixth' && role.level >= 60" />
            <Unauthorized v-if="activeName === 'sixth' && role.level < 60" />
          </el-tab-pane>
          <el-tab-pane label="资源监控" name="seventh">
            <Unavailable v-if="activeName === 'seventh' && role.level >= 10" />
            <Unauthorized v-if="activeName === 'seventh' && role.level < 10" />
          </el-tab-pane>
          <el-tab-pane label="一键订阅" name="eighth">
            <Subscribe v-if="activeName === 'eighth' && role.level >= 50" />
            <Unauthorized v-if="activeName === 'eighth' && role.level < 50" />
          </el-tab-pane>
          <el-tab-pane label="资源分享" name="ninth">
            <Share v-if="activeName === 'ninth' && role.level >= 10" />
            <Unauthorized v-if="activeName === 'ninth' && role.level < 10" />
          </el-tab-pane>
          <el-tab-pane label="直播代理" name="tenth">
            <Porxy v-if="activeName === 'tenth' && role.level >= 10" />
            <Unauthorized v-if="activeName === 'tenth' && role.level < 10" />
          </el-tab-pane>
          <el-tab-pane v-if="role.name === 'admin'" label="用户管理" name="eleventh">
            <ManageUser v-if="activeName === 'eleventh'" />
          </el-tab-pane>
          <el-tab-pane label="个人设置" name="twelve">
            <UserSettings v-if="activeName === 'twelve'" />
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
    <!-- 尾部 -->
    <div class="footer-container">
      <div class="footer-section">
        <div class="copyright">© 2024 全王探测 All rights reserved.</div>
      </div>
      <div class="footer-section">
        <div class="social-icons">
          <a class="icon-link" href="https://github.com/Subdue0/ip-scanner" title="GitHub项目地址" target="_blank"><i class="fab fa-github"></i></a>
          <a class="icon-link" href="https://t.me/+Cr9uNkqY-uI5ZDRl" title="加入TG群" target="_blank"><i class="fab fa-telegram"></i></a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Search from '@/components/Search.vue'
import Parse from '@/components/Parse.vue'
import CurrentTask from '@/components/CurrentTask.vue'
import ManageTask from '@/components/ManageTask.vue'
import TaskResult from '@/components/TaskResult.vue'
import ManageUser from '@/components/ManageUser.vue'
import Unavailable from '@/components/Unavailable.vue'
import Subscribe from '@/components/Subscribe.vue'
import Share from '@/components/Share.vue'
import Porxy from '@/components/Porxy.vue'
import Unauthorized from '@/components/Unauthorized.vue'
import DDNS from '@/components/DDNS.vue'
import UserSettings from '@/components/UserSettings.vue'
export default {
  name: 'Home',
  components: {
    Search,
    Parse,
    CurrentTask,
    ManageTask,
    TaskResult,
    ManageUser,
    Unavailable,
    Subscribe,
    Share,
    Porxy,
    Unauthorized,
    DDNS,
    UserSettings,
  },
  data() {
    return {
      activeName: '',
      role: '',
    };
  },
  mounted() {
    // 读取激活的tab页面，若不存在则使用第一个
    this.activeName = localStorage.getItem('tab') || 'first';
    // 读取角色信息，若不存在则使用默认角色
    this.role = JSON.parse(localStorage.getItem('role')) || { name: 'guest', text: '游客', level: 1 };
  },
  // 在路由离开前，清除子组件的定时器
  beforeRouteLeave(to, from, next) {
    if (this.$refs.currentTaskRef) {
      this.$refs.currentTaskRef.clearTimer();
    }
    if (this.$refs.manageTaskRef) {
      this.$refs.manageTaskRef.clearTimer();
    }
    next(); // 继续路由跳转
  },
  methods: {
    selectTab(e) {
      localStorage.setItem('tab', e.name);
    },
    toTab(name) {
      this.activeName = name;
    },
  }
}
</script>

<style scoped lang="scss">
#home ::v-deep {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  .header-container {
    /* 修改标题样式 */
    .title {
      font-family: '方正王献之小楷 简';
      font-size: 40px;
      font-weight: bold;
      text-align: center;
      color: #1E9FFF;
    }
  }
  .content-container {
    flex: 1;
    /* 标签页样式 */
    .tab-container {
      padding: 20px;
      box-sizing: border-box;
      .el-tabs {
        .el-tabs__header.is-top {
          border-bottom: 1px solid rgba(30, 159, 255, 0.5);
          .el-tabs__nav-wrap {
            .el-tabs__nav-scroll {
              .el-tabs__nav.is-top {
                border: 1px solid rgba(30, 159, 255, 0.5);
                .el-tabs__item.is-top {
                  font-family: '方正王献之小楷 简';
                  font-size: 20px;
                  border-left: 1px solid rgba(30, 159, 255, 0.8);
                  &:first-child {
                    border-left: none;
                  }
                  &.is-active {
                    color: #1E9FFF;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .footer-container {
    flex-shrink: 0;
    padding: 10px 0;
    white-space: nowrap;
    .footer-section {
      .copyright {
        color: rgba(30, 159, 255, 0.5);
      }
      .social-icons {
        padding: 10px;
        .icon-link {
          &:not(:last-child) {
            margin-right: 30px;
          }
          i {
            color: rgba(30, 159, 255, 0.5);
          }
        }
      }
    }
  }
}
</style>
