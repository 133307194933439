<template>
  <div class="manage-task-container">
    <!-- 添加任务 -->
    <div class="dialog-container">
      <el-dialog :title="optDialog === 'add' || !isManage ? '添加任务' : '编辑任务'"
        :visible="optDialog === 'add' || optDialog === 'edit'" width="30%" :before-close="closeOptDialog"
        :close-on-click-modal="false">
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="80px">
          <el-form-item label="探测方式" prop="mode">
            <el-select v-model="ruleForm.mode.selectedName" placeholder="请选择" @change="selectMode">
              <el-option v-for="item in ruleForm.mode.options" :key="item.name" :label="item.text"
                :value="item.name"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item v-if="ruleForm.mode.selectedName === 'single'" label="端口" prop="port">
            <el-input type="number" v-model="ruleForm.port" placeholder="仅支持单端口，多端口请添加多个任务"></el-input>
          </el-form-item>
          <el-form-item v-if="ruleForm.mode.selectedName === 'single'" label="地理位置" prop="location.checkList">
            <el-checkbox-group v-model="ruleForm.location.checkList">
              <el-checkbox label="中国"></el-checkbox>
              <el-checkbox label="香港"></el-checkbox>
              <el-checkbox label="澳门"></el-checkbox>
              <el-checkbox label="台湾"></el-checkbox>
            </el-checkbox-group>
          </el-form-item>
          <el-form-item v-if="ruleForm.mode.selectedName === 'full'" label="地理位置" prop="location.selectedCity">
            <el-cascader v-model="ruleForm.location.selectedCity" :props="ruleForm.location.props"
              @change="selectLocation"></el-cascader>
          </el-form-item>
          <el-form-item v-if="ruleForm.mode.selectedName === 'custom'" label="端口" prop="mutiport">
            <el-input type="text" v-model="ruleForm.mutiport" placeholder="支持多端口，以英文逗号间隔" @input="limitInput"></el-input>
          </el-form-item>
          <el-form-item v-if="ruleForm.mode.selectedName === 'custom'" label="IP范围" prop="ipRanges">
            <el-upload ref="fileUpload" action="" drag accept=".txt" :limit="1" :auto-upload="false"
              :on-change="uploadLimit">
              <i class="el-icon-upload"></i>
              <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
              <div class="el-upload__tip" slot="tip">仅支持上传txt文件，请使用非Windows自定带的文本编辑器编辑模板文件（<em
                  @click="downloadDemo">上传模板下载</em>）</div>
            </el-upload>
          </el-form-item>
          <el-form-item label="类别" prop="type">
            <el-select v-model="ruleForm.type.selectedIndex" placeholder="请选择" @change="selectType">
              <el-option v-for="item in ruleForm.type.options" :key="item.index" :label="item.name" :value="item.index">
                <span style="display: flex; justify-content: space-between; align-items: center;">
                  <span style="flex: 4; overflow: hidden; white-space: nowrap; text-overflow: ellipsis;">{{ item.name
                  }}</span>
                  <span style="flex: 1;"></span>
                  <span
                    style="flex: 5; overflow: hidden; white-space: nowrap; text-overflow: ellipsis; color: #8492a6; font-size: 13px; text-align: right;">{{
                      item.uri }}</span>
                </span>
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="资源路径" prop="uri">
            <el-input v-model="ruleForm.uri" placeholder="/ZHGXTV/Public/json/live_interface.txt"></el-input>
          </el-form-item>
          <el-form-item label="关键词" prop="keyword">
            <el-input v-model="ruleForm.keyword" placeholder="用&表示和，用|表示或，用!表示非"></el-input>
          </el-form-item>
          <el-form-item label="备注" prop="remark">
            <el-tag :key="tag" v-for="tag in ruleForm.remark" closable :disable-transitions="false"
              @close="deleteRemark(ruleForm.remark)">
              {{ tag }}
            </el-tag>
            <el-input class="input-new-tag" v-if="ruleForm.inputVisible" v-model="ruleForm.inputValue" ref="saveTagInput"
              size="small" @keyup.enter.native="addRemark" @blur="addRemark">
            </el-input>
            <el-button v-else class="button-new-tag" size="small" @click="showInput">+
              新备注</el-button>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" v-throttle="submitForm">提交</el-button>
            <el-button @click="resetForm">重置</el-button>
          </el-form-item>
        </el-form>
      </el-dialog>
    </div>

    <!-- 删除提示 -->
    <div class="dialog-container">
      <el-dialog title="提示" :visible="optDialog === 'delete'" width="30%" :before-close="closeOptDialog"
        :close-on-click-modal="false">
        <span>是否确认删除？</span>
        <span slot="footer" class="dialog-footer">
          <el-button @click="optDialog = ''">我再想想</el-button>
          <el-button type="primary" v-throttle="deleteConfirmed">确 定</el-button>
        </span>
      </el-dialog>
    </div>

    <!-- 跳转提示 -->
    <div class="dialog-container">
      <el-dialog title="提示" :visible="optDialog === 'redirect'" width="30%" :before-close="closeOptDialog"
        :close-on-click-modal="false">
        <span>是否跳转到结果面板？</span>
        <span slot="footer" class="dialog-footer">
          <el-button @click="optDialog = ''">我再想想</el-button>
          <el-button type="primary" v-throttle="toResult">确 定</el-button>
        </span>
      </el-dialog>
    </div>

    <!-- 输入密码 -->
    <div class="dialog-container">
      <el-dialog title="密码" :visible="optDialog === 'manage'" width="30%" :before-close="closeOptDialog"
        :close-on-click-modal="false">
        <el-form>
          <el-input class="passsword-input" placeholder="请输入密码：123456" v-model="managePassword" show-password></el-input>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="optDialog = ''">取 消</el-button>
          <el-button type="primary" v-throttle="passwordConfirmed">确 定</el-button>
        </span>
      </el-dialog>
    </div>

    <!-- 表格容器 -->
    <div class="table-container">
      <!-- 按钮组 -->
      <div class="table-buttons">
        <!-- 管理按钮 -->
        <el-button class="el-button-manage" @click="manageTask">{{ manageText }}</el-button>
        <!-- 添加按钮 -->
        <el-button type="primary" @click="addTask">添加</el-button>
      </div>

      <!-- 表格内容 -->
      <el-table v-loading="loading" element-loading-text="加载中，请稍后..." :data="tableData" border>
        <!-- 表格列定义 -->
        <el-table-column prop="no" label="序号" width="50" header-align="center" align="center"
          :resizable=false></el-table-column>
        <el-table-column v-if="screenWidth > 767" prop="mode.text" label="探测方式" width="100" header-align="center"
          align="center" :resizable=false></el-table-column>
        <el-table-column prop="content" label="内容" header-align="center" align="center" :resizable=false
          show-overflow-tooltip></el-table-column>
        <el-table-column v-if="screenWidth > 767" prop="location" label="地理位置" width="180" header-align="center"
          align="center" :resizable=false></el-table-column>
        <el-table-column v-if="screenWidth > 767 && role.level > 10" prop="email" label="邮箱" width="180"
          header-align="center" align="center" :resizable=false></el-table-column>
        <el-table-column v-if="screenWidth > 767" prop="createdAt" label="创建时间" width="160" header-align="center"
          align="center" :resizable=false></el-table-column>
        <el-table-column v-if="screenWidth > 767" prop="updatedAt" label="修改时间" width="160" header-align="center"
          align="center" :resizable=false></el-table-column>
        <el-table-column v-if="screenWidth > 767" prop="isHidden" label="是否隐藏" width="60" header-align="center"
          align="center" :resizable=false></el-table-column>
        <el-table-column v-if="screenWidth > 767" prop="remark" label="备注" width="80" header-align="center" align="center"
          :resizable=false show-overflow-tooltip></el-table-column>
        <el-table-column v-if="!isManage" prop="status" label="状态" width="120" header-align="center" align="center"
          :resizable=false>
          <template slot-scope="scope">
            <!-- 加载状态 -->
            <el-skeleton v-if="scope.row.status === undefined" style="width: 100px" :loading="true" :rows="1"
              animated></el-skeleton>
            <!-- 加载完毕 -->
            <el-tag v-if="scope.row.status !== undefined"
              :type="scope.row.status === '已完成' ? 'success' : scope.row.status === '等待中' ? 'warning' : scope.row.status === '已停止' ? 'danger' : 'primary'"
              effect="dark">{{ scope.row.status }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column v-if="isManage" label="操作" width="240" header-align="center" align="center">
          <template slot-scope="scope">
            <el-button size="mini" @click="editTask(scope.$index, scope.row)">编辑</el-button>
            <el-button size="mini" type="danger" @click="deleteTask(scope.$index, scope.row)">删除</el-button>
            <el-button size="mini" type="success" @click="redirectTask(scope.$index, scope.row)">跳转</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import taskService from '@/api/services/taskService';
export default {
  name: 'ManageTask',
  data() {
    var validatePort = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入端口'));
      } else if (value < 0 || value > 65535) {
        callback(new Error('端口范围应在0到65535之间'));
      } else {
        callback();
      }
    };

    var validateMutiport = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入端口，多端口使用英文逗号间隔'));
      } else {
        // 将输入的字符串按逗号分隔成多个端口
        const ports = value.split(',');

        // 遍历每个端口，检查是否在合法范围内
        for (const port of ports) {
          const portNumber = parseInt(port.trim(), 10); // 转换为整数
          if (isNaN(portNumber) || portNumber < 0 || portNumber > 65535) {
            callback(new Error('端口范围应在0到65535之间'));
            return; // 如果发现有不合法的端口，立即返回错误
          }
        }

        // 所有端口都合法，调用 callback() 表示验证通过
        callback();
      }
    };

    var validateIpRanges = (rule, value, callback) => {
      if (!this.ruleForm.ipRangesFile) {
        callback(new Error('请上传文件'));
      } else {
        callback();
      }
    };

    var validateLocation = (rule, value, callback) => {
      if (rule.field === 'location.checkList') {
        if (value.length === 0) {
          callback(new Error('请至少选择一个地理位置'));
        } else {
          callback();
        }
      } else if (rule.field === 'location.selectedCity') {
        if (value.length === 0) {
          callback(new Error('请选择地理位置'));
        } else {
          callback();
        }
      } else {
        callback();
      }
    };

    var validateUri = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入资源路径'));
      } else if (!value.startsWith('/')) {
        callback(new Error('资源路径必须以/开头'));
      } else if (/[,，]/.test(value)) {
        callback(new Error('资源路径不能包含逗号'));
      } else {
        callback();
      }
    };

    var validateRemark = (rule, value, callback) => {
      if (!value || value.length === 0) {
        callback(new Error('备注不能为空'));
      } else {
        callback();
      }
    };

    return {
      loading: false, // 控制页面的加载状态
      role: {
        name: 'guest',
        text: '游客',
        level: 1
      }, // 用户默认角色
      ruleForm: {
        mode: {
          selectedName: 'single',
          options: [
            {
              name: 'single',
              text: '单端口探测'
            },
            {
              name: 'full',
              text: '全端口探测'
            },
            {
              name: 'custom',
              text: '自定义探测'
            }
          ]
        },
        port: '',
        location: {
          checkList: ['中国', '香港', '澳门', '台湾'],
          selectedCity: [],
          props: {
            lazy: true,
            lazyLoad(node, resolve) {
              if (node.level === 0) {
                // 获取省级数据
                taskService.getTaskProvinces().then(response => {
                  const provinces = response.data.map(item => ({
                    value: item.name,
                    label: item.name,
                    leaf: false
                  }));
                  resolve(provinces);
                }).catch(error => {
                  console.error(error); // 捕获并输出错误
                });
              } else if (node.level === 1) {
                taskService.getTaskCities({ province: node.label }).then(response => {
                  const cities = response.data.map((item, index) => ({
                    value: item.name,
                    label: item.name,
                    leaf: true
                  }));
                  resolve(cities);
                })
                  .catch(error => {
                    console.error(error); // 捕获并输出错误
                  });
              }
            }
          }
        },
        mutiport: '',
        ipRangesFile: null,
        type: {
          selectedIndex: 0,
          options: [{
            index: 0,
            name: '自定义',
            uri: '自定义',
            keyword: '',
            remark: [],
          }, {
            index: 1,
            name: '酒店-智能桌面',
            uri: '/iptv/live/1000.json',
            keyword: 'CCTV|翡翠|澳视|澳視|台视|台視',
            remark: ['酒店源', '直播源', '电视源'],
          }, {
            index: 2,
            name: '酒店-智慧光迅',
            uri: '/ZHGXTV/Public/json/live_interface.txt',
            keyword: 'CCTV|翡翠|澳视|澳視|台视|台視',
            remark: ['酒店源', '直播源', '电视源'],
          }, {
            index: 3,
            name: '酒店-秒开',
            uri: '/api/post?item=itv_traffic',
            keyword: 'CCTV|翡翠|澳视|澳視|台视|台視',
            remark: ['酒店源', '直播源', '电视源'],
          }, {
            index: 4,
            name: '酒店-华视美达',
            uri: '/newlive/manager/index.php',
            keyword: '华视美达',
            remark: ['酒店源', '直播源', '电视源'],
          }, {
            index: 5,
            name: '酒店-维盟',
            uri: '/dpl/PotPlayerMini.dpl',
            keyword: 'CCTV|翡翠|澳视|澳視|台视|台視',
            remark: ['酒店源', '直播源', '电视源'],
          }, {
            index: 6,
            name: '酒店-腾龙',
            uri: '/login?redirect=%2Fmanage',
            keyword: '<title>TamronOS IPTV系统</title>',
            remark: ['酒店源', '直播源', '电视源'],
          }, {
            index: 7,
            name: '酒店-威堡',
            uri: '/admin/user/login.html',
            keyword: '<title>威堡IPTV电视管理后台</title>',
            remark: ['酒店源', '直播源', '电视源'],
          }, {
            index: 8,
            name: '酒店-毒盒',
            uri: '/',
            keyword: '<meta name="author" content="luo2888" />',
            remark: ['酒店源', '直播源', '电视源'],
          }, {
            index: 9,
            name: '组播-udpxy',
            uri: '/status',
            keyword: 'udpxy and udpxrec are Copyright',
            remark: ['组播源', '直播源', '电视源'],
          }, {
            index: 10,
            name: '地波-MuMuDVB',
            uri: '/channels_list.html',
            keyword: 'MuMuDVB',
            remark: ['地波源', '直播源', '电视源'],
          }, {
            index: 11,
            name: '地波-TVH',
            uri: '/playlist/ticket/channels',
            keyword: 'CCTV|翡翠|澳视|澳視|台视|台視',
            remark: ['地波源', '直播源', '电视源'],
          }, {
            index: 12,
            name: 'VIP视频解析',
            uri: '/jiexi/?url=',
            keyword: '视频URL地址不能为空|视频URL地址&接口!水印',
            remark: ['VIP视频解析源', '流媒体源', '电视源'],
          }, {
            index: 13,
            name: 'CDN加速节点',
            uri: '/',
            keyword: '<center>cloudflare</center>',
            remark: ['Cloudflare CDN加速'],
          }]
        },
        uri: '',
        keyword: '',
        remark: [],
        inputVisible: false,
        inputValue: '',
      },
      rules: {
        mode: [
          { required: true, trigger: 'blur' }
        ],
        port: [
          { required: true, validator: validatePort, trigger: 'blur' }
        ],
        'location.checkList': [
          { required: true, validator: validateLocation, trigger: 'change' }
        ],
        'location.selectedCity': [
          { required: true, validator: validateLocation, trigger: 'change' }
        ],
        mutiport: [
          { required: true, validator: validateMutiport, trigger: 'blur' }
        ],
        ipRanges: [
          { required: true, validator: validateIpRanges, trigger: 'change' }
        ],
        type: [
          { required: true, trigger: 'blur' }
        ],
        uri: [
          { required: true, validator: validateUri, trigger: 'blur' }
        ],
        remark: [
          { required: true, validator: validateRemark, trigger: 'blur' }
        ],
      },
      isManage: false,
      manageText: '管理',
      popoverVisible: false,
      optDialog: '',
      managePassword: '',
      index: 0,
      screenWidth: window.innerWidth,
      // 表格初始数据
      tableData: [
      ]
    };
  },
  mounted() {
    // 初始化时加载数据
    this.getData();

    // 读取角色信息，若不存在则使用默认角色
    this.role = JSON.parse(localStorage.getItem('role')) || { name: 'guest', text: '游客', level: 1 };

    // 设置窗口调整大小时的监听器
    window.addEventListener('resize', this.resize);

    // 设定定时器
    this.setupTimer();
  },
  beforeDestroy() {
    // 清除定时器
    this.clearTimer();
    // 监听窗口大小
    window.removeEventListener('resize', this.resize);
  },
  activated() {
    this.setupTimer();
  },
  deactivated() {
    this.clearTimer();
  },
  methods: {
    setupTimer() {
      // 清除现有的定时器以避免重复
      this.clearTimer();

      // 启动定时器，并将其保存在组件的 timer 属性中
      this.timer = setInterval(() => {
        this.updateTableStatus();
      }, 10000); // 10000 毫秒表示10秒
    },
    clearTimer() {
      if (this.timer) {
        clearInterval(this.timer);
        this.timer = null; // 清除后重置定时器标识
      }
    },
    // 监听窗口大小
    resize() {
      this.screenWidth = window.innerWidth;
    },
    // 获取数据
    getData() {
      // 表格加载中
      this.loading = true;
      // 通过 taskService 获取任务列表信息
      taskService.listTasks().then(response => {
        // 构建表格数据
        this.tableData = response.data.map((item, index) => {
          // 返回表格数据对象
          return {
            no: (index + 1), // 序号
            id: item.id, // 任务 ID
            mode: {
              name: item.mode_name, // 探测模式名称
              text: item.mode_text, // 探测模式描述
            },
            location: item.location.join('、'), // 探测位置
            content: `端口: ${item.port}, 资源路径: ${item.uri}, 关键词: ${item.keyword}`, // 探测内容
            email: item.email, // 电子邮件
            isHidden: item.is_hidden ? '是' : '否', // 是否隐藏
            createdAt: item.created_at, // 创建时间
            updatedAt: item.updated_at, // 修改时间
            remark: item.remark.join('、'), // 备注信息
          };
        });
        // 更新表格数据的状态
        this.updateTableStatus();
      })
        .catch(error => {
          console.error(error); // 捕获并输出错误
        });
    },
    // 更新表格数据的状态
    updateTableStatus() {
      // 通过 taskService 获取当前任务信息
      taskService.getCurrentTask().then(response => {
        // 提取响应中的任务 id、阶段和进度信息
        const ids = response.data.ids; // 任务 id 数组
        const stage = response.data.stage; // 任务阶段
        const progress = response.data.progress; // 任务进度
        const idsIndex = response.data.ids_index; // 任务索引

        this.tableData.forEach((item, index) => {
          // 获取任务状态
          const status = this.getStatus(index, idsIndex, stage, progress);
          // 使用 this.$set 方法更新对象属性
          this.$set(item, 'status', status);
        });
      }).catch(error => {
        console.error(error); // 捕获并输出错误
      })
        .finally(() => {
          // 表格加载完毕
          this.loading = false;
        });
    },
    // 根据索引、阶段和进度信息获取任务状态
    getStatus(index, idsIndex, stage, progress) {
      // 判断阶段是否包含 '_wait' 或者 'stop' 字符串
      const containsSubStr = stage.includes('_wait') || stage.includes('stop');
      let status = ''; // 初始化状态
      // 如果当前索引在激活索引列表中
      if (idsIndex.includes(index)) {
        // 获取阶段名称
        const stageName = this.getStageName(stage);
        if (containsSubStr) {
          // 如果阶段包含 '_wait' 字符串，则状态为阶段名称
          status = stageName;
        } else {
          // 否则状态为阶段名称 + 进度
          status = progress + ' ' + stageName;
        }
      } else if (idsIndex.some(idIndex => index < idIndex)) {
        // 当前索引在最小激活索引之前，视为已完成
        status = '已完成';
      } else if (idsIndex.some(idIndex => index > idIndex)) {
        // 当前索引在最大激活索引之后，视为等待中
        status = '等待中';
      }

      return status; // 返回任务状态
    },
    // 根据阶段名称获取对应的状态名称
    getStageName(stage) {
      // 使用 switch-case 结构根据不同的阶段名称返回对应的状态名称
      switch (stage) {
        case 'ready':
          return '准备';
        case 'merge':
          return '合并';
        case 'split':
          return '分块';
        case 'discover':
          return '发现';
        case 'discover_wait':
          return '发现即将结束';
        case 'scan':
          return '扫描';
        case 'scan_wait':
          return '扫描即将结束';
        case 'check':
          return '检测';
        case 'check_wait':
          return '检测即将结束';
        case 'done':
          return '已完成';
        case 'stop':
          return '已停止';
        default:
          return '未知阶段'; // 如果阶段未知，则返回默认值
      }
    },
    selectMode(name) {
      this.ruleForm.mode.selectedName = name;
      if (name === 'single') {
        this.ruleForm.location.selectedCity = [];
      } else if (name === 'full') {
        this.ruleForm.port = '';
        this.ruleForm.location.checkList = ['中国', '台湾', '澳门', '香港'];
      } else if (name === 'custom') {
        this.ruleForm.mutiport = '';
        this.ruleForm.ipRangesFile = null;
      }
      this.$refs.ruleForm.clearValidate(); // 清除所有字段的验证状态
    },
    selectLocation(value) {
      this.ruleForm.location.selectedCity = value;
    },
    selectType(index) {
      if (index) {
        this.ruleForm.uri = this.ruleForm.type.options[index].uri;
        this.ruleForm.keyword = this.ruleForm.type.options[index].keyword;
        this.ruleForm.remark = this.ruleForm.type.options[index].remark;
      } else {
        this.ruleForm.uri = '';
        this.ruleForm.keyword = '';
        this.ruleForm.remark = [];
      }
      this.$refs.ruleForm.clearValidate(); // 清除所有字段的验证状态
    },
    manageTask() {
      if (this.manageText === '管理') {
        // 权限不足提示
        if (this.role.level <= 10) {
          this.$message({
            message: '您无权执行此操作，请联系管理员提升权限',
            type: 'error',
            duration: 5000
          });
          return;
        }
        this.optDialog = 'manage';
        // 打开密码对话框时重置数据
        this.managePassword = '';
      } else {
        this.manageText = '管理';
        this.isManage = false;
      }
    },
    validatePassword() {
      const password = this.managePassword.trim();
      if (!password) {
        this.$message.error('密码不能为空');
        return false;
      } else if (!/^[a-zA-Z0-9!@#$%^&*()_+.]*$/.test(password)) {
        this.$message.error('密码只能包含字母、数字和符号');
        return false;
      }
      return true;
    },
    passwordConfirmed() {
      if (this.validatePassword()) {
        // 准备请求参数
        const params = {
          password: this.managePassword
        };
        taskService.manageTask(params).then(response => {
          // 变换表格状态
          this.isManage = true;
          // 改变按钮文字
          this.manageText = '完成';
        })
          .catch(error => {
            console.error(error); // 捕获并输出错误
          })
          .finally(() => {
            // 关闭窗口
            this.optDialog = '';
          });
      }
    },
    addTask() {
      this.optDialog = 'add';
      // 打开添加对话框时重置数据
      this.resetForm();
    },
    closeOptDialog() {
      // 关闭窗口
      this.optDialog = '';
    },
    limitInput(value) {
      // 只保留数字和逗号
      const filteredValue = value.replace(/[^\d,]/g, '');
      // 更新输入框的值
      this.ruleForm.mutiport = filteredValue;
    },
    downloadDemo() {
      const ipRanges = `
1.0.1.0-1.0.1.255
1.0.2.0-1.0.3.255
1.0.8.0-1.0.15.255
`.trim() + '\n'; // 使用trim()去除多余的空行，并手动添加一个空行
      const blob = new Blob([ipRanges], { type: 'text/plain' });
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = 'ip_ranges.txt';
      link.click();
      URL.revokeObjectURL(link.href); // 释放URL对象
    },
    uploadLimit(file, fileList) {
      // 源类型是否为 txt 文本文件
      if (!file.raw.type.includes('text')) {
        // 文件类型错误提示
        this.$message.error('请上传扩展名为 .txt 的文件');
        // 清空文件列表
        this.$refs.fileUpload.clearFiles();
      }
      // 存储文件源数据
      this.ruleForm.ipRangesFile = file.raw;
    },
    deleteRemark(remark) {
      const index = this.ruleForm.remark.indexOf(remark);
      this.ruleForm.remark.splice(index, 1);
      this.$refs.ruleForm.validateField('remark');
    },
    showInput() {
      this.ruleForm.inputVisible = true;
      this.$nextTick(_ => {
        this.$refs.saveTagInput.$refs.input.focus();
      });
    },
    addRemark() {
      let inputValue = this.ruleForm.inputValue;
      if (inputValue) {
        this.ruleForm.remark.push(inputValue);
      }
      this.ruleForm.inputVisible = false;
      this.ruleForm.inputValue = '';
    },
    submitForm() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          // 暂未开放提示
          if (this.ruleForm.mode.selectedName === 'custom') {
            // 关闭窗口
            this.optDialog = '';
            this.$message({
              message: '此功能暂未开放使用',
              type: 'error',
              duration: 3000
            });
            return;
          }

          // 准备请求参数
          let params = {
            mode: this.ruleForm.mode.selectedName,
            uri: this.ruleForm.uri,
            keyword: this.ruleForm.keyword,
            remark: this.ruleForm.remark,
            is_hidden: 0,
          };

          // 探测方式
          const mode = this.ruleForm.mode.selectedName;
          if (mode === 'single') {
            params.port = this.ruleForm.port;
            params.location = this.ruleForm.location.checkList;
          } else if (mode === 'full') {
            params.port = 0;
            params.location = this.ruleForm.location.selectedCity;
          } else if (mode === 'custom') {
            params.mutiport = this.ruleForm.mutiport;
            params.location = ['自定义'];

            // 创建 FormData 对象
            const formData = new FormData();
            // 添加文件对象到 FormData
            formData.append('ip_ranges', this.ruleForm.ipRangesFile);

            // 将 params 对象中的键值对逐一添加到 formData 对象中
            for (const key in params) {
              if (params.hasOwnProperty(key)) {
                formData.append(key, params[key]);
              }
            }

            // 将 formData 对象重新赋值给 params
            params = formData;
          }

          // 权限不足提示
          if (this.role.level <= 10) {
            // 关闭窗口
            this.optDialog = '';
            this.$message({
              message: '您无权执行此操作，请联系管理员提升权限',
              type: 'error',
              duration: 5000
            });
            return;
          }

          if (this.optDialog === 'add') {
            taskService.addNewTask(params).then(response => {
              // 获取数据
              this.getData();
              // 输出成功提示
              this.$message.success(response.message);
            })
              .catch(error => {
                console.error(error); // 捕获并输出错误
              })
              .finally(() => {
                // 关闭窗口
                this.optDialog = '';
              });
          } else if (this.optDialog === 'edit') {
            params.id = this.tableData[this.index].id;
            taskService.editTask(params).then(response => {
              // 获取数据
              this.getData();
              // 输出成功提示
              this.$message.success(response.message);
            })
              .catch(error => {
                console.error(error); // 捕获并输出错误
              })
              .finally(() => {
                // 关闭窗口
                this.optDialog = '';
              });
          }
        } else {
          this.$message.error('请检查表单数据！');
        }
      });
    },
    resetForm() {
      // 清除所有字段的验证状态
      this.$nextTick(() => {
        this.$refs.ruleForm.clearValidate();
      });

      // 重置数据
      this.ruleForm.mode.selectedName = 'single';
      this.ruleForm.location.checkList = ['中国', '台湾', '澳门', '香港'];
      this.ruleForm.location.selectedCity = [];
      this.ruleForm.port = '';
      this.ruleForm.type.selectedIndex = 0;
      this.ruleForm.uri = '';
      this.ruleForm.keyword = '';
      this.ruleForm.remark = [];
      this.ruleForm.inputVisible = '';
      this.ruleForm.inputValue = '';
    },
    editTask(index, row) {
      // 记录当前编辑任务的索引
      this.index = index;

      // 打开编辑对话框
      this.optDialog = 'edit';

      // 打开编辑对话框时重置数据
      this.resetForm();

      // 根据行数据的模式设置表单的模式选择
      if (row.mode.name === 'single') {
        // 如果模式是单一模式，设置表单的模式为单端口模式，并设置已选地理位置
        this.ruleForm.mode.selectedName = 'single';
        this.ruleForm.location.checkList = row.location.split('、');
      } else {
        // 如果模式是完整模式，设置表单的模式为全端口模式，并设置已选地理位置
        this.ruleForm.mode.selectedName = 'full';
        this.ruleForm.location.selectedCity = row.location.split('、');
      }

      // 解析行数据的内容，提取端口、资源路径和关键词，并设置到表单对应的字段中
      const content = row.content.match(/端口: (\d*), 资源路径: (.*), 关键词: (.*)/);
      this.ruleForm.port = content[1];
      this.ruleForm.uri = content[2];
      this.ruleForm.keyword = content[3];

      // 设置表单的邮箱字段为行数据中的邮箱
      this.ruleForm.email = row.email;

      // 将行数据中的备注拆分为数组，并设置到表单的备注字段中
      this.ruleForm.remark = row.remark.split('、');
    },
    deleteTask(index, row) {
      // 设置当前删除任务的索引
      this.index = index;

      // 打开删除确认框
      this.optDialog = 'delete';
    },
    deleteConfirmed() {
      // 准备请求参数
      const params = {
        id: this.tableData[this.index].id
      };
      taskService.deleteTask(params).then(response => {
        // 获取数据
        this.getData();
        // 输出成功提示
        this.$message.success(response.message);
      })
        .catch(error => {
          console.error(error); // 捕获并输出错误
        })
        .finally(() => {
          // 关闭窗口
          this.optDialog = '';
        });
    },
    redirectTask(index, row) {
      // 设置当前删除任务的索引
      this.index = index;

      // 打开跳转确认框
      this.optDialog = 'redirect';
    },
    // 跳转到结果面板
    toResult() {
      // 关闭窗口
      this.optDialog = '';

      // 从表格数据中获取备注信息，并截取第一个条目
      const remark = this.tableData[this.index].remark.split('、')[0];

      // 从浏览器中获取存储的 token
      const token = localStorage.getItem('token');

      // 构建请求 URL
      const url = process.env.VUE_APP_API_URL + '/result/auth.php?token=' + token + '&search=' + remark;

      // 在新标签页中打开链接
      window.open(url, '_blank');
    },
  },
}
</script>

<style scoped lang="scss">
.manage-task-container ::v-deep {
  .dialog-container {
    .el-dialog__wrapper {
      .el-form-item__content {

        .el-select,
        .el-cascader,
        .el-checkbox-group,
        .el-upload,
        .el-upload-dragger {
          width: 100%;
        }

        .el-upload__tip {
          em {
            color: #409EFF;
            cursor: pointer;
          }
        }

        .el-tag+.el-tag {
          margin-left: 10px;
        }

        .button-new-tag {
          margin-left: 10px;
          height: 32px;
          line-height: 30px;
          padding-top: 0;
          padding-bottom: 0;
        }

        .input-new-tag {
          width: 90px;
          margin-left: 10px;
          vertical-align: bottom;
        }
      }
    }
  }

  /* 移动端样式（小于 1200px） */
  @media screen and (max-width: 1200px) {
    .dialog-container {
      .el-dialog {
        width: 90% !important;
      }
    }

    /* 表格最大高度 */
    .el-table {
      max-height: 500px;
    }
  }

  /* PC端样式（大于等于 1200px） */
  @media screen and (min-width: 1200px) {

    /* 表格最大高度 */
    .el-table {
      max-height: 380px;
    }
  }

  /* 表格相关样式 */
  .table-container {
    padding: 0 20px;

    /* 按钮组样式 */
    .table-buttons {
      margin: 10px 0;
      text-align: right;
    }

    /* 表格内整体样式 */
    .el-table {
      position: relative;
      overflow-y: auto;
      /* 表格外边框颜色 */
      border: 2px solid #4682B4;

      /* 去掉表格底部多余的白色边框 */
      &::before {
        height: 0;
      }

      /* 设置表格遮罩为全屏 */
      .el-loading-mask {
        position: fixed;
      }

      /* 表格头部样式 */
      .el-table__header-wrapper {
        position: sticky;
        top: 0;
        z-index: 1;
        background-color: #FFFFFFAA;
      }

      /* 表头单元格边框颜色 */
      .el-table__header-wrapper thead th {
        border-right: 2px solid #4682B4 !important;
        border-bottom: 2px solid #4682B4 !important;
      }

      /* 去除表头最后一个单元格右边框颜色 */
      .el-table__header-wrapper thead th:nth-last-child(2) {
        border-right: none !important;
      }

      /* 内部单元格边框颜色 */
      .el-table__body-wrapper tbody tr td,
      .el-table__fixed tbody tr td {
        border-right: 2px solid #4682B4 !important;
        border-bottom: 2px solid #4682B4 !important;
      }

      /* 去除内部单元格最后一个单元格右边框颜色 */
      .el-table__body-wrapper tbody tr td:nth-last-child(1),
      .el-table__fixed tbody tr td:nth-last-child(1) {
        border-right: none !important;
      }

      /* 去除最后一行内部单元格下边框颜色 */
      .el-table__body-wrapper tbody tr:nth-last-child(1) td,
      .el-table__fixed tbody tr:nth-last-child(1) td {
        border-bottom: none !important;
      }

      /* 表格内背景颜色透明 */
      th.el-table__cell,
      tr,
      td {
        background-color: transparent;
      }
    }

    .el-table--border {

      /* 去掉表格右侧多余的白色边框 */
      &::after {
        width: 0;
      }
    }

    /* 最外层透明 */
    .el-table,
    .el-table__expanded-cell {
      background-color: transparent;
    }
  }
}
</style>
